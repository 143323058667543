.irpd-item {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
  width: 100%;
  /*
    min width fixo utlizado  para  nao  expandir
    o pivot item no momento de mostrar o loading
    da varredura, mantendo seu tamanho  estavel.
  */
  min-width: 300px;
  overflow: hidden;

  &:hover {
    background-color: #fafafa;
  }

  & > * {
    user-select: none;
  }

  img {
    padding: 0.1px 0px 0px 9px; //Por algum motivo o padding aqui não se comporta como o do pivotItem. A solução que eu encontrei foi essa.
    height: 90px;
    object-fit: contain;
    align-self: center;
  }

  &__row-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__row-right {
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__title {
    display: flex;
    flex-direction: row;

    h4 {
      margin: 0px;
      font-size: 1.1rem;
      font-weight: 400;
      color: #000;
    }
  }

  &__battery {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 0.8rem;
    min-height: 18px;
    opacity: 0.9;
    margin-top: 8px;
  }

  &__status {
    font-weight: 600;
    font-size: 0.7rem;
    margin: 4px 0px 4px 8px;
    padding: 4px 8px;
    border-radius: 8px;
    color: white;
  }

  &__last-update {
    margin-top: 10px;
    color: rgb(110, 110, 110);
    font-size: 0.8rem;
  }

  &__info {
    font-weight: 400;
    font-size: 0.8rem;
  }

  &__text-container {
    display: flex;
    flex-direction: row;
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    padding: 0.8rem;
  }

  .MuiCircularProgress-root {
    padding: 13px;
    color: black;
  }
  .MuiSvgIcon-root {
    height: 17px;
    color: black;
  }
}
