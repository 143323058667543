@import "../../../../../../../../../../style/Mixins.scss";
@import "../../../../../../../../../../style/Vars.scss";

.root {
  position: relative;
  box-sizing: border-box;
  width: 100%;

  @include tablet-landscape-and-below {
    width: 100vw;
  }

  height: 100%;

  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 15px;

  & > .input-div {
    height: 100%;

    display: flex;

    flex-direction: column;

    margin-left: -15px; //Trick to do reverse padding so the borders extend to the edges
    margin-right: -15px;
    width: calc(100% + 30px);
    padding: 15px;
  }
}

.create-user {
  &__social-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    // height: auto;
  }

  &__lang-container {
    margin-bottom: 8px;
  }

  &__language-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__text {
    @include tablet-landscape-and-below {
      padding-top: 20%;
    }
    font-size: 18px;
    line-height: 21px;

    color: #979797;
  }

  &__admin-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-start;

    width: 100%;
    // height: auto;
    padding: 0px;
    margin: 35px 0px 10px 0px;
  }

  &__email {
    display: flex;
    width: 100%;

    .MuiInputLabel-root {
      font-size: 1.05em;
    }

    .MuiInput-root {
      font-size: 1.05em;
    }

    .MuiInputBase-input {
      font-size: 1.05em;
    }
  }

  &__button-container {
    display: flex;
    align-items: center;

    position: relative;
    bottom: 10%;

    width: 100%;
    height: 60px;

    padding: 15px;
  }
}
